import store from "@/store";

const isTokenValid = async () => {
  return new Promise(resolve => {
    store
      .dispatch("auth/validateToken")
      .then(value => {
        resolve(value);
      })
      .catch(error => {
        resolve(error);
      });
  });
};

export default async (to, from, next) => {
  const tokenValid = await isTokenValid();

  if (store.getters["auth/isAuthenticated"] && tokenValid === true) {
    next();
    return;
  } else if (typeof tokenValid === "string") {
    window.location.href = tokenValid;
  }
  next(false);
};
