import Vue from "vue";
import App from "@/gullkit/App.vue";
import VueCompositionAPI from "@vue/composition-api";
import router from "./router/index.js";
import store from "./store";
// import "babel-polyfill";
// import VueRouter from "vue-router";
import GullKit from "./gullkit/plugins/gull.kit";
// import "babel-polyfill";
// import es6Promise from "es6-promise";
// es6Promise.polyfill();
import Breadcumb from "./gullkit/components/breadcumb.vue";
// import firebase from "firebase/app";
// import "firebase/auth";
// import { firebaseSettings } from "@/gullkit/data/config";
import i18n from "./gullkit/lang/lang";
// import DateRangePicker from "vue2-daterange-picker";

//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
//import material-icon scss
import "font-awesome/css/font-awesome.min.css";

//defined as global component
Vue.component("VueFontawesome", require("vue-fontawesome-icon/VueFontawesome.vue").default);

Vue.component("breadcumb", Breadcumb);
import InstantSearch from "vue-instantsearch";
// Vue.use(VueRouter);

Vue.use(InstantSearch);
Vue.use(GullKit);

// firebase.initializeApp(firebaseSettings);

Vue.use(VueCompositionAPI);

import ApiClient from "@/ApiClient";
import { addInterceptors } from "@reivernet/cognito-package/src/axios/interceptors";
addInterceptors(ApiClient, store);

Vue.config.productionTip = false;
import "vue-select/dist/vue-select.css";

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
