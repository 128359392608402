import Hotel from "@/classes/hotel";
import { Action, ActionContext, GetterTree, MutationTree } from "vuex";
import ApiClient from "@/ApiClient";

type HotelState = {
  hotels: Hotel[];
  loading: boolean;
};

type HotelMutations = MutationTree<HotelState> & {
  SET_LOADING(state: HotelState, isLoading: boolean): void;
  SET_HOTELS(state: HotelState, items: Hotel[]): void;
};

type HotelActions = {
  getHotels(context: ActionContext<HotelState, any>): Promise<any>;
  getHotelByDns(context: ActionContext<HotelState, any>, siteDns: string): Promise<Hotel | null | undefined>;
};

type HotelGetters = GetterTree<HotelState, any> & {
  hotels(state: HotelState): Hotel[];
};

const state: HotelState = {
  hotels: [],
  loading: false
};

const mutations: HotelMutations = {
  SET_LOADING(state: HotelState, isLoading: boolean) {
    state.loading = isLoading;
  },
  SET_HOTELS(state: HotelState, items: Hotel[]) {
    state.hotels = items;
  }
};

const actions: HotelActions = {
  getHotelByDns(context: ActionContext<HotelState, any>, siteDns: string): Promise<Hotel | null | undefined> {
    return new Promise(resolve => {
      if (state.hotels.length === 0) {
        context
          .dispatch("getHotels")
          .then(() => {
            console.log("looking in state");
            return state.hotels.find(hotel => hotel.dnsName === siteDns);
          })
          .then(hotel => {
            console.log(hotel);
            resolve(hotel);
          });
      } else {
        resolve(state.hotels.find(hotel => hotel.dnsName === siteDns));
      }
    });
  },
  getHotels(context: ActionContext<HotelState, any>): Promise<any> {
    return new Promise((resolve, reject) => {
      ApiClient.get("/api/hotels.json")
        .then(response => {
          return response.data;
        })
        .then(data => {
          context.commit("SET_HOTELS", data);
        })
        .then(() => {
          resolve(true);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const getters: HotelGetters = {
  hotels(state: HotelState): Hotel[] {
    return state.hotels;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
