import { ActionContext, MutationTree } from "vuex";
import ApiClient from "@/ApiClient";

type PortMappingActionContext = ActionContext<PortMappingState, any>;

type PortMappingState = {
  coreSwitches: [];
  edgeSwitches: [];
  loading: boolean;
};

type PortMappingMutations = MutationTree<PortMappingState> & {
  SET_CORE_SWITCHES(state: PortMappingState, switches: []): void;
  SET_EDGE_SWITCHES(state: PortMappingState, switches: []): void;
  SET_LOADING(state: PortMappingState, isLoading: boolean): void;
};

type PortMappingActions = {
  fetchCoreSwitches(context: PortMappingActionContext, site: string): Promise<any>;
  fetchEdgeSwitches(context: PortMappingActionContext, site: string): Promise<any>;
};

type PortMappingGetters = {
  coreSwitches(state: PortMappingState): [];
  edgeSwitches(state: PortMappingState): [];
  loading(state: PortMappingState): boolean;
};

const state: PortMappingState = {
  coreSwitches: [],
  edgeSwitches: [],
  loading: false
};

const mutations: PortMappingMutations = {
  SET_CORE_SWITCHES(state: PortMappingState, switches: []) {
    state.coreSwitches = switches;
  },
  SET_EDGE_SWITCHES(state: PortMappingState, switches: []) {
    state.edgeSwitches = switches;
  },
  SET_LOADING(state: PortMappingState, isLoading: boolean) {
    state.loading = isLoading;
  }
};

const actions: PortMappingActions = {
  fetchCoreSwitches(context: PortMappingActionContext, site: string) {
    return new Promise((resolve, reject) => {
      context.commit("SET_LOADING", true);
      ApiClient.get(`/api/core_switches.json?site=${site}`)
        .then(response => {
          return response.data;
        })
        .then(data => {
          context.commit("SET_CORE_SWITCHES", data);
          context.commit("SET_LOADING", false);
          resolve();
        })
        .catch(e => {
          context.commit("SET_LOADING", false);
          reject(e);
        });
    });
  },
  async fetchEdgeSwitches(context: PortMappingActionContext, site: string) {
    try {
      context.commit("SET_LOADING", true);
      const response = await ApiClient.get(`/api/edge_switches.json?site=${site}`);
      if (response) {
        context.commit("SET_EDGE_SWITCHES", response.data);
      }
      context.commit("SET_LOADING", false);
    } catch (e) {
      context.commit("SET_LOADING", false);
      console.log(e);
    }
  }
};

const getters: PortMappingGetters = {
  coreSwitches(state: PortMappingState) {
    return state.coreSwitches;
  },
  edgeSwitches(state: PortMappingState) {
    return state.edgeSwitches;
  },
  loading(state: PortMappingState) {
    return state.loading;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
