import Vue from "vue";
import store from "../store";
// import {isMobile} from "mobile-device-detect";
import Router from "vue-router";
import NProgress from "nprogress";
import authenticate from "../gullkit/auth/authenticate";
// import AuthCallback from "@/views/AuthCallback";

import LoginForm from "@reivernet/cognito-package/src/components/LoginForm";
import RequestPasswordResetForm from "@reivernet/cognito-package/src/components/RequestPasswordResetForm";
import ResetPasswordForm from "@reivernet/cognito-package/src/components/ResetPasswordForm";
import AuthCallback from "@reivernet/cognito-package/src/views/AuthCallback";
import LogoutCallback from "@reivernet/cognito-package/src/views/LogoutCallback";

Vue.use(Router);

// create new router

const routes = [
  {
    path: "/",
    component: () => import("../gullkit/views/app"), //webpackChunkName app
    beforeEnter: authenticate,
    redirect: "/app/dashboard",

    children: [
      {
        path: "/app/dashboard",
        component: () => import("../gullkit/views/app/dashboards"), //dashboard
        children: [
          {
            path: "",
            name: "dashboard.v1",
            component: () => import("../gullkit/views/app/dashboards/dashboard.v1"),
            beforeEnter: authenticate
          }
          // {
          //   path: "dashboard.v2",
          //   name: "dashboard.v2",
          //   component: () => import("../gullkit/views/app/dashboards/dashboard.v2")
          // },
          // {
          //   path: "dashboard.v3",
          //   name: "dashboard.v3",
          //   component: () => import("../gullkit/views/app/dashboards/dashboard.v3")
          // },
          // {
          //   path: "dashboard.v4",
          //   name: "dashboard.v4",
          //   component: () => import("../gullkit/views/app/dashboards/dashboard.v4")
          // }
        ]
      },
      {
        path: "/app/port-mapping",
        name: "portMapping",
        beforeEnter: authenticate,
        component: () => import("@/views/PortMapping")
      },
      {
        path: "/app/switch-upload",
        name: "switchUpload",
        beforeEnter: authenticate,
        component: () => import("@/views/SwitchUpload")
      },
      {
        path: "/app/backups/:directory(.*)?",
        name: "backups",
        beforeEnter: authenticate,
        props: true,
        component: () => import("@/views/Backups")
      },

      {
        path: "/logout",
        name: "LogoutCallback",
        component: LogoutCallback,
        props: {
          successRoute: "login"
        }
      },
      {
        path: "/app/port-mapping/document/:siteDns",
        name: "PortMappingDocument",
        beforeEnter: authenticate,
        component: () => import("@/views/PortMappingDocument"),
        props: true
      }
    ]
  },
  {
    path: "/login",
    component: () => import("../gullkit/views/app/sessions"), //webpackChunkName app
    children: [
      {
        path: "",
        name: "login",
        component: LoginForm
      },
      {
        path: "forgot",
        name: "RequestPasswordReset",
        component: RequestPasswordResetForm
      },
      {
        path: "resetPassword",
        name: "ResetPassword",
        component: ResetPasswordForm,
        props: true
      }
    ]
  },
  // {
  //   path: "users",
  //   children: [
  //     {
  //       path: "",
  //       name: "userList",
  //       component: UserList
  //     },
  //     {
  //       path: "edit/:id?",
  //       name: "userEdit",
  //       props: true,
  //       component: UserForm
  //     }
  //   ]
  // },
  {
    path: "/oauth/login",
    component: () => import("../gullkit/views/app"), //webpackChunkName app
    children: [
      {
        path: "",
        component: AuthCallback,
        name: "AuthCallback",
        props: {
          successRoute: "dashboard.v1"
        }
      }
    ]
  },
  // sessions
  // {
  //   path: "/app/sessions",
  //   component: () => import("../gullkit/views/app/sessions"),
  //   redirect: "/app/sessions/signIn",
  //   children: [
  //     {
  //       path: "signIn",
  //       component: () => import("../gullkit/views/app/sessions/signIn")
  //     },
  //     {
  //       path: "signUp",
  //       component: () => import("../gullkit/views/app/sessions/signUp")
  //     },
  //     {
  //       path: "forgot",
  //       component: () => import("../gullkit/views/app/sessions/forgot")
  //     }
  //   ]
  // },

  {
    path: "/vertical-sidebar",
    component: () => import("../gullkit/containers/layouts/verticalSidebar")
  },
  {
    path: "*",
    component: () => import("../gullkit/views/app/pages/notFound")
  }
];

const index = new Router({
  mode: "history",
  linkActiveClass: "open",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

index.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.

    NProgress.start();
    NProgress.set(0.1);
  }
  next();
});

index.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.getElementById("loading_wrap");
  if (gullPreLoading) {
    gullPreLoading.style.display = "none";
  }
  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500);
  // NProgress.done();
  // if (isMobile) {
  if (window.innerWidth <= 1200) {
    // console.log("mobile");
    store.dispatch("changeSidebarProperties");
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
      store.dispatch("changeCompactSidebarProperties");
    }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch("changeSecondarySidebarProperties");
    }

    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  }
});

export default index;
