import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import hotel from "@/store/hotel";
import portMapping from "@/store/portMapping";

import largeSidebar from "@/gullkit/store/modules/largeSidebar.js";
import compactSidebar from "@/gullkit/store/modules/compactSidebar.js";
// import chat from "@/gullkit/store/modules/chat.js";
import config from "@/gullkit/store/modules/config.js";
// import authData from "@/gullkit/store/modules/authData.js";
// import invoice from "@/gullkit/store/modules/invoice.js";
// import cart from "@/gullkit/store/modules/cart.js";
// import verticalSidebar from "@/gullkit/store/modules/verticalSidebar.js";
// import scrumboard from "@/gullkit/store/modules/scrumboard.js";

import { AuthFactory } from "@reivernet/cognito-package/src/store/auth";
import { UsersFactory } from "@reivernet/cognito-package/src/store/users";

import apiClient from "@/ApiClient";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: AuthFactory(apiClient),
    // users: UsersFactory(apiClient),
    hotel,
    portMapping,

    largeSidebar, //gull
    compactSidebar, //gull
    // chat, //gull
    config //gull
    // authData, //gull
    // invoice, //gull
    // cart, //gull
    // verticalSidebar, //gull
    // scrumboard //gull
  }
});
